/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,400;1,500&display=swap'); */

/*@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./fonts/Montserrat/static/Montserrat-Medium.ttf");
}*/

/*@font-face {
	font-family: "SF Pro";
	src: local("SF Pro"), url("./fonts/SFPro/TrueType/SFProDisplay-Medium.ttf");
}*/

@import url('./assets/font-awesome/css/font-awesome.min.css');
@import url('./fonts/SFPro/fontface-sfpro.css');
@import url('./assets/boxicons/css/boxicons.min.css');
@import url('./assets/boxicons/css/animations.css');
@import url('./assets/boxicons/css/transformations.css');
@import url('./fonts/DeltaFire/deltafire-icons.css');

#root {
  min-height: 100%;  
}

* {
	font-family: 'SF Pro', sans-serif;
}

body {
  background-color: #212121;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header,
footer { 
	width: 100% !important; 
}

header .logo {
	position: relative;
	width: 245px;
	height: 50px;
	overflow: hidden;
	margin: 0;
}

header .logo img {
	max-width: 245px;
	max-height: 50px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	object-fit: contain;
}

footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #1D1D1D;
	border-top: 1px solid #5E5E5E;
}

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.text-small { font-size: small; font-weight: 400; }
.text-medium { font-size: medium; font-weight: 400; }
.text-larger { font-size: larger; font-weight: 400; }

.text-monospace { font-family: monospace; }

.h1 {
	font-size: 34px;
}
.h2 {
	font-size: 30px;
}
.h3 {
	font-size: 26px;
}
.h4 {
	font-size: 22px;
}
.h5 {
	font-size: 20px;
}
.h6 {
	font-size: 18px;
}

.mt-0 {
	margin-top: 0px !important;
}
.mt-1 {
	margin-top: 5px !important;
}
.mt-2 {
	margin-top: 10px !important;
}
.mt-3 {
	margin-top: 15px !important;
}
.mt-4 {
	margin-top: 20px !important;
}
.mt-5 {
	margin-top: 25px !important;
}

.ml-0 {
	margin-left: 0px !important;
}
.ml-1 {
	margin-left: 5px !important;
}
.ml-2 {
	margin-left: 10px !important;
}
.ml-3 {
	margin-left: 15px !important;
}
.ml-4 {
	margin-left: 20px !important;
}
.ml-5 {
	margin-left: 25px !important;
}

.mr-0 {
	margin-right: 0px !important;
}
.mr-1 {
	margin-right: 5px !important;
}
.mr-2 {
	margin-right: 10px !important;
}
.mr-3 {
	margin-right: 15px !important;
}
.mr-4 {
	margin-right: 20px !important;
}
.mr-5 {
	margin-right: 25px !important;
}

.mb-0 {
	margin-bottom: 0px !important;
}
.mb-1 {
	margin-bottom: 5px !important;
}
.mb-2 {
	margin-bottom: 10px !important;
}
.mb-3 {
	margin-bottom: 15px !important;
}
.mb-4 {
	margin-bottom: 20px !important;
}
.mb-5 {
	margin-bottom: 25px !important;
}

.ml-auto { margin-left: auto !important; }
.mr-auto { margin-right: auto !important; }
.mt-auto { margin-top: auto !important; }
.mb-auto { margin-bottom: auto !important; }

.mx-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

.mx-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.mx-1 {
	margin-left: 5px !important;
	margin-right: 5px !important;
}
.mx-2 {
	margin-left: 10px !important;
	margin-right: 10px !important;
}
.mx-3 {
	margin-left: 15px !important;
	margin-right: 15px !important;
}
.mx-4 {
	margin-left: 20px !important;
	margin-right: 20px !important;
}
.mx-5 {
	margin-left: 25px !important;
	margin-right: 25px !important;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.my-1 {
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}
.my-2 {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}
.my-3 {
	margin-top: 15px !important;
	margin-bottom: 15px !important;
}
.my-4 {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}
.my-5 {
	margin-top: 25px !important;
	margin-bottom: 25px !important;
}

.m-2 { margin: 10px !important; }

.px-0 { padding: 0px; }
.px-1 { padding: 0 5px; }
.px-2 { padding: 0 10px; }
.px-3 { padding: 0 15px; }
.px-4 { padding: 0 20px; }
.px-5 { padding: 0 25px; }

.pb-0 { padding-bottom: 0px; }
.pb-1 { padding-bottom: 5px; }
.pb-2 { padding-bottom: 10px; }
.pb-3 { padding-bottom: 15px; }
.pb-4 { padding-bottom: 20px; }
.pb-5 { padding-bottom: 25px; }

@media(max-width: 767px) {
	.mt-sm-0 {
		margin-top: 0px !important;
	}
	.mt-sm-1 {
		margin-top: 5px !important;
	}
	.mt-sm-2 {
		margin-top: 10px !important;
	}
	.mt-sm-3 {
		margin-top: 15px !important;
	}
	.mt-sm-4 {
		margin-top: 20px !important;
	}
	.mt-sm-5 {
		margin-top: 25px !important;
	}
	
	.mb-sm-0 {
		margin-bottom: 0px !important;
	}
	.mb-sm-1 {
		margin-bottom: 5px !important;
	}
	.mb-sm-2 {
		margin-bottom: 10px !important;
	}
	.mb-sm-3 {
		margin-bottom: 15px !important;
	}
	.mb-sm-4 {
		margin-bottom: 20px !important;
	}
	.mb-sm-5 {
		margin-bottom: 25px !important;
	}

	.ml-sm-0 {
		margin-left: 0px !important;
	}
	.ml-sm-1 {
		margin-left: 5px !important;
	}
	.ml-sm-2 {
		margin-left: 10px !important;
	}
	.ml-sm-3 {
		margin-left: 15px !important;
	}
	.ml-sm-4 {
		margin-left: 20px !important;
	}
	.ml-sm-5 {
		margin-left: 25px !important;
	}
	
	.mr-sm-0 {
		margin-right: 0px !important;
	}
	.mr-sm-1 {
		margin-right: 5px !important;
	}
	.mr-sm-2 {
		margin-right: 10px !important;
	}
	.mr-sm-3 {
		margin-right: 15px !important;
	}
	.mr-sm-4 {
		margin-right: 20px !important;
	}
	.mr-sm-5 {
		margin-right: 25px !important;
	}

	.mx-sm-0 {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.mx-sm-1 {
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
	.mx-sm-2 {
		margin-left: 10px !important;
		margin-right: 10px !important;
	}
	.mx-sm-3 {
		margin-left: 15px !important;
		margin-right: 15px !important;
	}
	.mx-sm-4 {
		margin-left: 20px !important;
		margin-right: 20px !important;
	}
	.mx-sm-5 {
		margin-left: 25px !important;
		margin-right: 25px !important;
	}
}

.w-100 {
	width: 100%;
}
.mw-100 {
	min-width: 100%;
}
.h-100{
	height: 100%;
}

.d-flex { display: flex; }
.d-flex--center { justify-content: center; align-items: center; }
.d-flex--end { justify-content: flex-end; align-items: center; }
.d-flex--column { flex-direction: column; }
.d-flex--row { flex-direction: row; }
.d-flex--align-items-center { align-items: center;}

.container {
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
}

.container--fluid {
	max-width: none !important;
	width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
}

.container--min-height {
	min-height: 100vh;
}

.container--centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

.container--larger {
	max-width: calc(100vw - 189px);
}

.container--commands {
	max-width: calc(100vw - 125px);
	margin: 0 0 0 auto !important;
}

@media(max-width: 1300px)
{
	.container {
		max-width: 1180px;
	}
}

@media(max-width: 1200px)
{
	.container {
		max-width: 860px;
	}
}
@media(max-width: 992px)
{
	.container {
		max-width: 720px;
	}
}
@media(max-width: 768px)
{
	.container {
		max-width: 576px;
	}
}
@media(max-width: 576px)
{
	.container {
		max-width: 500px;
	}
}

@media(max-width: 500px)
{
	.container {
		max-width: calc(100% - 30px);
		margin: 0 auto;
	}
}

/* Ícones Delta Fire */
.df-i 
{
	display: inline-block;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	filter: brightness(0);
}

.df-i-2xs { width: 10px; height: 10px; }
.df-i-xs { width: 12px; height: 12px; }
.df-i-sm { width: 14px; height: 14px; }
.df-i-md { width: 18px; height: 18px; }
.df-i-lg { width: 20px; height: 20px; }
.df-i-xl { width: 24px; height: 24px; }
.df-i-2xl { width: 32px; height: 32px; }

.df-i-white {filter: invert(1) !important; }
.df-i-red { filter: invert(40%) sepia(64%) saturate(5702%) hue-rotate(344deg) brightness(103%) contrast(101%) !important; }

.df-i-config { background-image: url("./assets/icons/icon_configuracoes.png"); }
.df-i-events { background-image: url("./assets/icons/icon_eventos.png"); }
.df-i-groups { background-image: url("./assets/icons/icon_grupos.png"); }
.df-i-peripheral { background-image: url("./assets/icons/icon_perifericos.png"); }
.df-i-network {	background-image: url("./assets/icons/icon_rede.png"); }
.df-i-relatory { background-image: url("./assets/icons/icon_relatorios.png"); }
.df-i-emergency { background-image: url("./assets/icons/icon_emergencia.png"); }
.df-i-system { background-image: url("./assets/icons/icon_sistema.png"); }
.df-i-dashboard { background-image: url("./assets/icons/icon_dashboard.png"); }
.df-i-brigade { background-image: url("./assets/icons/icon_fireman.png"); }
.df-i-abandon { background-image: url("./assets/icons/icon_abandon.png"); }
.df-i-silence { background-image: url("./assets/icons/icon_silence.png"); }
.df-i-reset { background-image: url("./assets/icons/icon_reset.png"); }
.df-i-pdf { background-image: url("./assets/icons/icon_file_pdf.png"); }

.fa[animation-iteration-count="1"],
.bx[animation-iteration-count="1"],
.df-i[animation-iteration-count="1"] {
	animation-iteration-count: 1;
}

/* Buttons */
.button {
	position: relative;
	display: flex;
    align-items: center;
    justify-content: center;
	min-height: 44px;
    background-color: #5E5E5E;
	padding: 10px;
    border-width: 0px;
	border-radius: 10px;
	overflow: hidden;
	transition: all .2s linear;
	z-index: 1;
}
.button:hover{
    cursor: pointer;
	background-color: #505050;
	box-shadow: 0px 0px 4px 2px rgba(255,255,255,0.5);
}
.button:disabled {
	background-color: #929292;
	cursor: not-allowed;
}
.button:hover:disabled {
	background-color: #929292;
	cursor: not-allowed;
}
.button:disabled .df-i {
	filter: invert(99%) sepia(3%) saturate(493%) hue-rotate(321deg) brightness(108%) contrast(67%) !important;
}

.button--xs {
	width: max-content;
}
.button--sm {
	padding: 10px 15px;
}
.button--md {
	padding: 10px 25px;
}
.button--lg {
	padding: 10px 75px;
}

@media(max-width: 1280px)
{
	.button--lg {
		padding: 10px 65px;
	}
}

.button__loading {
	display: none;
	color: #FFF;
	fill: #FFF;
	z-index: 2;
}

.button__badge {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(50%, -50%); /* Ajusta o posicionamento */
	min-width: 20px;
	height: 20px;
	padding: 0 6px;
	background-color: #5ACBFA; /* Cor principal do badge */
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	line-height: 20px;
	text-align: center;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
	border-radius: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra para dar destaque */
	pointer-events: none; /* Impede interação direta com o badge */
}

.button__icon {
	color: #FFF;
	fill: #FFF;
	z-index: 2;
}
.df-i.button__icon {
	filter: invert(1);
}

.button__label {
	font-size: 16px;
	color: #FFF;
	z-index: 2;
}

.button__icon + .button__label {
	margin-left: 10px;
}
.button__label + .button__icon {
	margin-right: 10px;
}

.button--success { background-color: #34C759; }
.button--success:hover { background-color: #2E7D32; }

.button--warning { background-color: #FFCC00; }
.button--warning:hover { background-color: #CCA300 }

.button--alt { background-color: #FF9500; }
.button--alt:hover { background-color: #cc7700; }

.button--danger { background-color: #FF3B30; }
.button--danger:hover { background-color: #c90b00; }

.button--primary { background-color: #5ACBFA; }
.button--primary:hover { background-color: #079EDF;  }

.button--block { background-color: #c90b00; cursor: not-allowed !important; pointer-events: none;}
.button--primary.button--block { background-color: #079EDF; }
.button--success.button--block { background-color: #2E7D32; }

.button--transparent { background-color: transparent; }
.button--transparent:hover { background-color: transparent; box-shadow: none; }
.button--transparent:disabled { background-color: transparent; }
.button--transparent:hover:disabled { background-color: transparent; box-shadow: none; }

.button--outline-warning {
	border: 2px solid #FFCC00;
}
.button--outline-warning .button__badge {
	background-color: #FFCC00;
}
.button--outline-warning i {
	color: #FFCC00 !important;
}
.button--outline-danger {
	border: 2px solid #FF3B30;
}
.button--outline-danger .button__badge {
	background-color: #FF3B30;
}
.button--outline-danger i {
	color: #FF3B30 !important;
}

.button--loading {
	pointer-events: none;
	animation: neon-loading 1.5s linear infinite;
}
.button--loading::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background-color: rgba(0,0,0,0.3);
	z-index: 1;
}
.button--loading .button__icon {
	display: none;
}
.button--loading .button__loading {
	display: block;
}

.button--alerted i {
	animation: alert-on 1.2s linear infinite;
}
.button--alarmed i {
	animation: alarmed-on 1.2s linear infinite;
}
.button--reseted {
	animation: reset-on 1.2s linear infinite;
}

.button--command {
	flex-direction: column;
	width: 165px;
	height: 145px;
	color: #FFF;
}

.button--no-hover {
	pointer-events: none;
}

@keyframes neon-loading {
	0% {
		border-color: #FFF;
		box-shadow: 0 0 7px 0 #FFF;
	}
	50% {
		border-color: #FFFFFFAA;
		box-shadow: 0 0 0px 0 #FFF;
	}
	100% {
		border-color: #FFF;
		box-shadow: 0 0 7px 0 #FFF;
	}
}


.buttons-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: max-content;
}

.buttons-container .button {
	max-height: 44px;
	margin: 0px 5px;
}

.buttons-container .button:first-child {
	margin-left: 0px;
}
.buttons-container .button:last-child {
	margin-right: 0px;
}

.buttons-container--center {
	margin: auto;
}

.buttons-container--right {
	margin-left: auto;
}

@media(max-width: 600px){
	.buttons-container {
		width: 100%;
		flex-wrap: wrap;
	}

	.buttons-container .button {
		width: 100%;
		padding: 10px;
		margin: 10px 0px;
	}

	.buttons-container--invert-mobile {
		flex-direction: column-reverse;
	}
}

.buttons-container--vertical {
	flex-direction: column;
	flex: 1;
}

.buttons-container--vertical .button--command {
	max-height: none;
	margin: 15px 0;
}

.buttons-container--vertical .button--command:first-child {
	margin-top: 0;
}
.buttons-container--vertical .button--command:last-child {
	margin-bottom: 0;
}

@media(min-aspect-ratio: 16/10)
{
	.buttons-container--vertical .button--command {
		width: 161px;
		height: 16vh;
		margin: 0;
	}
}

@media(min-aspect-ratio: 16/9)
{
	.buttons-container--vertical .button--command {
		width: 165px;
		height: 17vh;
		margin: 0;
	}
}

@media(max-width: 1400px)
{
	.buttons-container--vertical .button--command {
		width: 145px;
		height: 15vh;
		margin: 0;
	}

	.buttons-container--vertical .button--command .button__label {
		font-size: 14px;
	}
}


.buttons-container--stretch {
	width: 100%;
	justify-content: stretch;
}
.buttons-container--stretch .button {
	flex: 1;
}

/*CORES*/
.color-light {color: #929292;}
.color-dark {color: #5E5E5E;}
.color-disabled {color: #5E5E5E;}
.color-success {color: #34C759;}
.color-warning {color: #FFCC00;}
.color-danger {color: #FF3B30; }
.color-info {color: #5ACBFA;}

.item1.MuiBox-root {
	width: 0;
	overflow: hidden;
}
/* .MuiButtonBase-root.MuiIconButton-root {
	display: none;
} */

/* HEADER */
/* DARK MODE */
.app-header[data-dark-mode="true"] {
	background-color: #1D1D1D !important;
}

/* LIGHT MODE */
.app-header[data-dark-mode="false"] {
	background-color: #D6D6D6 !important;
}

.app-header .app-header__container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.app-header .app-header__container .app-header__container__wrap {
	display: flex;
}
.app-header .app-header__container .app-header__container__wrap--left {
	justify-content: flex-start;
}
.app-header .app-header__container .app-header__container__wrap--center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	/* justify-content: center; */
}
.app-header .app-header__container .app-header__container__wrap--right {
	justify-content: flex-end;
}

.header-action {
	min-width: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #5E5E5E;
	padding: 10px;
	border-width: 0px;
	border-radius: 10px;
	margin: 0 5px;
	transition: all .2s linear;
	min-height: 44px;
}


	.header-action i.fa,
	.header-action svg {
		color: #FFF;
		fill: #FFF;
		pointer-events: none;
		font-size: 1.5em;
	}

.header-action i.df-i {
	filter: invert(1) brightness(1);
}

.header-action:hover {
	box-shadow: 0px 0px 4px 2px rgba(255,255,255,0.5);
	background-color: #505050;
    cursor: pointer;
}

.header-action:disabled,
.header-action--disabled {
	background-color: #929292;
	cursor: not-allowed;
	pointer-events: none;
}

.header-action:disabled i.fa,
.header-action:disabled svg,
.header-action--disabled i.fa,
.header-action--disabled svg {
	color: #D5D5D5;
	fill: #D5D5D5;
}

.header-action:disabled i.df-i,
.header-action--disabled i.df-i {
	filter: invert(99%) sepia(1%) saturate(60%) hue-rotate(242deg) brightness(103%) contrast(67%);
}

	.header-action.header-action--success i.fa,
	.header-action.header-action--success svg {
		color: #34C759;
		fill: #34C759;
	}
.header-action.header-action--warning i.fa,
.header-action.header-action--success svg {
	color: #FFCC00;
	fill: #FFCC00;
}
.header-action.header-action--danger i.fa,
.header-action.header-action--success svg {
	color: #FF3B30;
	fill: #FF3B30;
}
.header-action.header-action--info i.fa,
.header-action.header-action--success svg {
	color: #5ACBFA;
	fill: #5ACBFA;
}
.header-action.header-action--success i.df-i {
	filter: invert(82%) sepia(21%) saturate(5001%) hue-rotate(168deg) brightness(101%) contrast(96%);
}
.header-action.header-action--warning i.df-i {
	filter: invert(76%) sepia(81%) saturate(1373%) hue-rotate(356deg) brightness(102%) contrast(106%);
}
.header-action.header-action--danger i.df-i {
	filter: invert(32%) sepia(16%) saturate(7422%) hue-rotate(339deg) brightness(104%) contrast(104%);
}
.header-action.header-action--info i.df-i {
	filter: invert(62%) sepia(62%) saturate(398%) hue-rotate(155deg) brightness(103%) contrast(96%);
}

.header-action.header-action--animation i.fa,
.header-action.header-action--animation svg{
	animation: alert-on 1.2s linear infinite;
}

.header-action.header-action--animation i.df-i {
	animation: alert-on-df-i 1.2s linear infinite;
}
.header-action-border {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 10px;
	border: 1px solid;
	padding: 5px 0px;
	border-color: #5E5E5E;
	margin-inline: 5px;
}
.hidden {
	display: none; 
}

/* Defina a animação com @keyframes */
@keyframes alarmed-on {
	0% {
		color: #FF3B30;
		fill: #FF3B30;
	}
	50% {
		color: #5E5E5E;
		fill: #5E5E5E;
	}
	100% {
		color: #FF3B30;
		fill: #FF3B30;
	}
}
@keyframes alert-on {
	0% {
		color: #FFCC00;
		fill: #FFCC00;
	}
	50% {
		color: #5E5E5E;
		fill: #5E5E5E;
	}
	100% {
		color: #FFCC00;
		fill: #FFCC00;
	}
}
@keyframes alert-on-df-i {
	0% {
		filter: invert(76%) sepia(81%) saturate(1373%) hue-rotate(356deg) brightness(102%) contrast(106%);
	}
	50% {
		filter: invert(61%) sepia(10%) saturate(0%) hue-rotate(237deg) brightness(97%) contrast(70%);
	}
	100% {
		filter: invert(76%) sepia(81%) saturate(1373%) hue-rotate(356deg) brightness(102%) contrast(106%);
	}
}
/* MENU APPBAR USER */
#menu-appbar-user .MuiMenu-paper 
{
	top: 66px !important;
}

/* MODAL (HTML/CSS) */
.modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	z-index: 1202;
	min-width: 300px;
	min-height: 300px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background-color: #2A2A2A;
	border: 3px solid #FFF;
	border-radius: 20px;
	box-shadow: 0px 0px 10px 0 rgba(255,255,255,1);
	visibility: hidden;
	opacity: 0;
	transition: all .2s linear;
}
.modal--priority-4 {
	z-index: 1203;
}
.modal--priority-5 {
	z-index: 1204;
}
.modal__outarea {
	z-index: -1;
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background-color: rgba(0,0,0,0.7);
}
.modal__close {
	width: 24px;
	height: 24px;
	display: block;
	text-align: center;
	border-radius: 50%;
	background-color: #FF3B30;
	cursor: pointer;
	transition: all .2s linear;
	margin-left: auto;
	margin-right: 10px;
	margin-top: 10px;
}
.modal__close:hover {
	background-color: #c90b00;
	box-shadow: 0 0 7px 0 #FFF;
}
.modal__close--hide {
	background-color: transparent;
	pointer-events: none;
}

.modal__title {
	font-size: 18px;
	margin: 0;
}
.modal__header {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
}
.modal__body {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: all .2s linear;
}
.modal__footer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 15px;
	transition: all .2s linear;
}

input[data-control="modal"] {
	display: none;
}
input[data-control="modal"]:not(:checked) + .modal {
	max-width: 0;
	max-height: 0;
}
input[data-control="modal"]:checked + .modal {
	max-width: 100vw;
	max-height: 100vh;
	visibility: visible;
	opacity: 1;
}

.modal.modal--command {
	/*overflow: hidden;*/
	width: 450px;
	border-radius: 13px;
}
.modal.modal--command .modal__header {
	min-height: 50px;
	position: absolute;
	top: 0;
	z-index: -1;
	color: #212121;
	text-transform: uppercase;
	text-shadow: 0px -1px 0.7px rgba(56,56,56,1);
	border-radius: 10px 10px 0 0;
	margin-bottom: 0;
}
.modal.modal--command .modal__footer {
	margin-top: 0;
}
.modal.modal--command .modal__footer {
	margin-top: 0;
}
.modal.modal--brigade .modal__header {
	background-color: #FF9500;
}
.modal.modal--brigade .modal__body i {
	color: #FF9500;
}
.modal.modal--abandon .modal__header {
	background-color: #FF3B30;
}
.modal.modal--abandon .modal__body i {
	color: #FF3B30;
}
.modal.modal--reset .modal__header {
	background-color: #34C759;
}
.modal.modal--reset .modal__body i {
	color: #34C759;
}
.modal.modal--visual .modal__header {
	background-color: #FFCC00;
}
.modal.modal--visual .modal__body i {
	color: #FFCC00;
}
.modal.modal--visual .modal__footer .button--warning .button__label {
	font-weight: bold;
	color: #212121;
}

/* DRAWER (HTML/CSS) */
.drawer {
	position: fixed;
	background-color: #2A2A2A;
	z-index: 3;
	visibility: hidden;
	opacity: 0;
	transition: all .2s linear;
}

.drawer--priority-5 {
	z-index: 5;
}
.drawer--priority-4 {
	z-index: 4;
}

.drawer--top {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-height: 0px;
	width: 100%;
    height: auto;
    top: 64px;
    left: 0px;
	padding: 0px 25px;
	border-bottom: 0px solid #5E5E5E;
}

.drawer--bottom {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-height: 0px;
	width: 100%;
    height: auto;
    bottom: 0px;
    left: 0px;
	padding: 0px 25px;
	border-bottom: 0px solid #5E5E5E;
}

.drawer--bottom-full {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-height: 0px;
	width: 100%;
    height: 0px;
    bottom: 0px;
    left: 0px;
	padding: 0px 25px;
	border-bottom: 0px solid #5E5E5E;
}

input[data-control="drawer"] {
	display: none;
}

input[data-control="drawer"]:checked + .drawer--top {
	max-height: 999999px;
	padding: 10px 25px;
	border-bottom: 1px solid #5E5E5E;
	box-shadow: 0px 2px 7px 4px rgba(0,0,0,0.5);
	visibility: visible;
	opacity: 1;
}
input[data-control="drawer"]:checked + .drawer--bottom {
	max-height: calc(100vh - 66px);
	padding: 10px 25px;
	border-top: 1px solid #5E5E5E;
	box-shadow: 0px 2px 7px 4px rgba(0,0,0,0.5);
	visibility: visible;
	opacity: 1;
}
input[data-control="drawer"]:checked + .drawer--bottom-full {
	max-height: calc(100vh - 66px);
	height: 100%;
	padding: 10px 25px;
	border-top: 1px solid #5E5E5E;
	box-shadow: 0px 2px 7px 4px rgba(0,0,0,0.5);
	visibility: visible;
	opacity: 1;
}

.drawer__close {
	width: 24px;
	height: 24px;
	display: block;
	text-align: center;
	border-radius: 50%;
	background-color: #FF3B30;
	cursor: pointer;
	transition: all .2s linear;
	margin-left: auto;
}
.drawer__close:hover {
	background-color: #c90b00;
	box-shadow: 0 0 7px 0 #FFF;
}

.drawer__header
{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.drawer__header__label {
	margin-top: 0;
}

.drawer__content {
	width: 100%;
	margin: 15px auto;
}

.drawer--bottom-full .drawer__content {
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #919191 transparent;
}

/* HINT BOX */
.hint-box {
	display: flex;
	background-color: rgba(94,94,94,0.2);
	padding: 10px 5px;
	border-radius: 8px;
}
.hint-box__icon {
	margin-right: 10px;
}
.hint-box__content {
	display: inline-block;
	text-align: left;
	letter-spacing: 0.75px;
}

/* FORM CONTROLS */
.form {
	display: flex;
	flex-direction: column;
}
.form__body {
	display: flex;

}
.form__body__row {
	min-width: 100%;
}
.form__body__column {
	flex: 1;
}
.form__footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
    
.form__item {
	position: relative;
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: stretch;
	margin: 5px 0;
}
.form__item__spy {
	position: absolute;
	right: 0px;
	top: calc(50% + 3px);
	transform: translate(0,-50%);
}
.form__item__spy:hover {
	box-shadow: none;
}
.form__item__control {
	flex: 3;
	min-height: 44px;
	font-size: 14px;
	color: #FFF;
	background-color: #3E3E3E;
	border-width: 1px;
	border-style: solid;
	border-color: #222;
	border-radius: 4px;
	padding-left: 5px;
	transition: all .2s linear;
}
.form__item__control:hover {
	border-color: #FFF;
}
.form__item__control:focus {
	background-color: #313131;
	border-color: #5ACBFA;
	outline: none;
}
.form__item--textarea {
	align-items: flex-start;
}
.form__item--textarea textarea {
	padding-top: 10px;
}
.form__item--select select:empty {
	color: #9E9E9E;
}
.form__item--label-vertical {
	align-items: flex-start;
	flex-direction: column;
}
.form__item--label-vertical  label {
	margin-bottom: 5px;
}

.form__item--label-vertical input, 
.form__item--label-vertical textarea,
.form__item--label-vertical select {
	width: -webkit-fill-available;
	width: -moz-available;
}

.form__item--label-horizontal label {
	flex: 1;
	margin-right: 10px;
}
.form__item--float label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translate(0, -50%);
    transition: all .2s linear;
}

.form__item--float label:before {
    content: "";
    width: 100%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #3E3E3E;
    z-index: -1;
}

/* Os seletores :has não são suportados em CSS3 nativo. */
.form__item--float label:focus-within,
.form__item--float label:not(:placeholder-shown) {
    top: 0%;
    left: 10px;
    font-size: 12px;
    padding: 0 3px;
}

.form__item--float label:focus-within:before {
    background-color: #313131;
}

.form__item--float input,
.form__item--float select,
.form__item--float textarea
{
	border: 1px solid #ffffff;
}

.form__item--float input:hover,
.form__item--float select:hover,
.form__item--float textarea:hover
{
	border: 1px solid #5ACBFA;
}

.form__item__control:focus,
.form__item__control:not(:placeholder-shown) {
	padding-left: 10px;
}

/* MENU */
.menu-lateral[data-view-touch="false"] {
	display: block;
    position: fixed;
    top: calc(50% + 33px);
    left: 15px;
    transform: translate(0, -50%);
    max-height: 610px;
    min-width: 60px;
    height: auto;
    min-height: 400px;
    z-index: 5;
	transition: all .2s linear;
}

.menu-lateral[data-view-touch="true"] {
	display: block;
    position: fixed;
    top: calc(50% + 33px);
    left: 0px;
    transform: translate(0, -50%);
    max-height: 610px;
    min-width: 35px;
    height: auto;
    min-height: 400px;
    z-index: 5;
	transition: all .2s linear;
}

/* MENU CLOSED */
.menu-lateral[data-view-touch="false"] .menu-lateral__list {
	width: 60px;
	border-radius: 20px;
	box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.3);
	transition: all .2s linear;
}

.menu-lateral[data-view-touch="true"] .menu-lateral__list {
	width: 38px;
	border-radius: 0 10px 10px 0;
	box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.3);
	transition: all .2s linear;
}
/* MENU OPENED */
.menu-lateral[data-view-touch="false"] .menu-lateral__list:hover {
	width: 240px !important;
	box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.3);
	transition: all .2s linear;
}

.menu-lateral[data-view-touch="true"] .menu-lateral__list:hover {
	width: 240px;
	border-radius: 0 20px 20px 0;
	box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.3);
	transition: all .2s linear;
}

/* DARK MODE */
.menu-lateral[data-dark-mode="true"] .menu-lateral__list {
	background-color: #5E5E5E;
	list-style: none;
	overflow: hidden;
	padding: 0px 0;
	margin: 0;
	transition: all .2s linear;
}

.menu-lateral[data-dark-mode="true"] .menu-lateral__list .menu-lateral__list__item {
	display: flex;
	align-items: center;
	color: #FFF;
	padding: 12px 15px;
}
.menu-lateral[data-dark-mode="true"] .menu-lateral__list .menu-lateral__list__item i.df-i {
	filter: invert(1);
}

/* LIGHT MODE */
.menu-lateral[data-dark-mode="false"] .menu-lateral__list {
	background-color: #D6D6D6;
    border-radius: 20px;
	overflow: hidden;
}
.menu-lateral[data-dark-mode="false"] .menu-lateral__list i.df-i {
	filter: invert(34%) sepia(0%) saturate(1206%) hue-rotate(245deg) brightness(102%) contrast(89%);
}

.menu-lateral[data-dark-mode="false"] .menu-lateral__list .menu-lateral__list__item {
	color: #5E5E5E;
}
.menu-lateral[data-dark-mode="false"] .menu-lateral__list .menu-lateral__list__item.Mui-selected {
	background-color: #E1E1E1;
}

/* MENU EVENTS */
.menu-lateral .menu-lateral__list .menu-lateral__list__item:hover {
	color: #5ACBFA;
	cursor: pointer;
	background-color: #5ACBFA1E;
}

.menu-lateral .menu-lateral__list .menu-lateral__list__item:hover i.df-i {
	filter: invert(70%) sepia(23%) saturate(950%) hue-rotate(155deg) brightness(98%) contrast(101%);
}

.menu-lateral .menu-lateral__list .menu-lateral__list__item--selected {
	color: #5ACBFA !important;
	background-color: #5ACBFA1E !important;
}

.menu-lateral .menu-lateral__list .menu-lateral__list__item--selected i.df-i {
	filter: invert(70%) sepia(23%) saturate(950%) hue-rotate(155deg) brightness(98%) contrast(101%) !important;
}

.menu-lateral .menu-lateral__list .menu-lateral__list__item .menu-lateral__list__item__icon,
.menu-lateral .menu-lateral__list .menu-lateral__list__item .menu-lateral__list__item__icon svg {
	color: inherit !important;
}

.menu-lateral .menu-lateral__list .menu-lateral__list__item .menu-lateral__list__item__icon svg[data-testid="TouchAppIcon"]{
	color: #FF3B30 !important;
}
.menu-lateral .menu-lateral__list .menu-lateral__list__item .menu-lateral__list__item__icon i[data-type="comandos"] {
	filter: invert(29%) sepia(42%) saturate(3683%) hue-rotate(343deg) brightness(105%) contrast(100%) !important;
}

.menu-lateral[data-view-touch="true"] .menu-lateral__list .menu-lateral__list__item {
	padding: 5px 6px;
	transition: all .2s linear;
}
.menu-lateral[data-view-touch="true"] .menu-lateral__list:hover .menu-lateral__list__item {
	padding: 8px 10px;
	transition: all .2s linear;
}

.menu-lateral[data-view-touch="false"] .menu-lateral__list .menu-lateral__list__item .menu-lateral__list__item__icon {
	position: absolute;
	top: 50%;
	left: 15px;
	transform: translate(0,-50%);
	display: flex;
	align-items: center;
	min-width: 30px !important;
	align-items: center;
    justify-content: center;
	z-index: 1;
}

/* MENU LABEL */
.menu-lateral .menu-lateral__list .menu-lateral__list__item {
	position: relative;
	width: 240px;
	height: 44px;
	white-space: nowrap !important;
	padding: 8px 15px;
	transition: all .2s linear;
}

.menu-lateral .menu-lateral__list .menu-lateral__list__item .menu-lateral__list__item__label {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 2;
	display: flex;
	align-items: center;
	padding-left: 64px;
}

/* MENU ICONES */
.menu-lateral[data-view-touch="true"] .menu-lateral__list .menu-lateral__list__item {
	height: 35px;
}
.menu-lateral[data-view-touch="true"] .menu-lateral__list .menu-lateral__list__item .menu-lateral__list__item__icon {
	position: absolute;
	top: 50%;
	left: 8px;
	transform: translate(0,-50%);
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
    justify-content: center;
	transition: all .2s linear;
}

.menu-lateral[data-view-touch="true"] .menu-lateral__list:hover .menu-lateral__list__item {
	height: 44px;
}

.menu-lateral[data-view-touch="true"] .menu-lateral__list:hover .menu-lateral__list__item .menu-lateral__list__item__icon {
	width: 24px;
	height: 24px;
	left: 15px;
}

.menu-lateral[data-view-touch="true"] .menu-lateral__list .menu-lateral__list__item .menu-lateral__list__item__label {
	padding-left: 64px;
	left: 50%;
	transform: translate(0,-50%);
	pointer-events: none;
	transition: all .2s linear;
}

.menu-lateral[data-view-touch="true"] .menu-lateral__list:hover .menu-lateral__list__item .menu-lateral__list__item__label {
	padding-left: 64px;
	left: 50%;
	transform: translate(-50%,-50%);
	pointer-events: all;
	transition: all .2s linear;
}

@media(max-width: 1300px)
{
	.menu-lateral[data-view-touch="false"] {
		left: 10px;
	}
}

/* COLLAPSABLE */

.collapsable {
	width: 100%;
}
.collapsable input[type="checkbox"].collapsable__toggle {
	display: none;
}

.collapsable input[type="checkbox"].collapsable__toggle:not(:checked) + .collapsable__content {
	height: 0%;
	max-height: 0;
	overflow: hidden;
	transition: all .2s linear;
}
.collapsable input[type="checkbox"].collapsable__toggle:checked + .collapsable__content {
	height: 100%;
	max-height: max-content;
	transition: all .2s linear;
}

/* LIST */
.list-container {
	position: relative;
}
.list-container .list-container__overlayer {
	position: absolute;
}
.list-container .list-container__overlayer.list-container__overlayer--marquee {
	top: 2px;
    left: 2px;
    width: calc(100% - 14px);
    height: 69px;
    background-color: #282828;
    border-radius: 6px;
    z-index: 3;
    pointer-events: none;
}

.list {
	min-height: calc(100vh - 28vh);
	max-height: calc(100vh - 28vh);
	display: flex;
	flex-direction: column;
    border-radius: 8px;
	border: 2px double #383838;
	background-color: rgba(94,94,94,0.1);
	list-style: none;
	padding: 10px 5px;
	overflow-y: scroll;
	scrollbar-width: thin;
	scrollbar-color: #919191 transparent;
}

/* Estilo para navegadores baseados em WebKit (Chromium, Edge, etc.) */
.list::-webkit-scrollbar {
	width: 8px; /* Largura da barra de rolagem */
}

.list::-webkit-scrollbar-thumb {
	background-color: #919191; /* Cor do "polegar" da barra */
	border-radius: 4px; /* Cantos arredondados */
}

.list::-webkit-scrollbar-track {
	background: transparent; /* Fundo da barra de rolagem */
}

.list__item {
	background-color: transparent;
	border: 2px solid #FFF;
	border-radius: 8px;
	padding: 0px 15px;
	margin: 4px 0;
	transition: all .2s linear;
}
.list__item:first-child {
	margin-top: 0px !important;
	margin-bottom: 16px;
    position: sticky;
    top: 0;
    z-index: 4;
}
.list__item:first-child.list__item:before {
	content: "";
    display: block;
    position: absolute;
    bottom: -13px;
    left: 50%;
	transform: translate(-50%,0);
    width: calc(100% - 15px);
    height: 1px;
    background-color: #929292;
	z-index: 1;
}
/* .list__item:last-child {
	margin-bottom: 0px !important;
} */
.list__item__label {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 44px;
	font-size: 18px;
	color: #FFF;
	margin: 0;
}

.list__item:hover {
	background-color: rgba(94,94,94,0.3);
	box-shadow: 0 2px 5px -1px #FFF;
}

.list.list--clickable li {
	cursor: pointer;
}

.list__item--locked {
	cursor: not-allowed;
	pointer-events: none;
}

.list__item--selected {
	background-color: #5ACBFA;
	border-color: #5ACBFA;
	box-shadow: none;
	margin: 8px 0;
}
.list__item--selected:hover {
	background-color: #079EDF;
	border-color: #079EDF;
	margin: 8px 0;
}
.list__item:first-child.list__item--selected {
	margin-bottom: 15px;
}
.list__item:first-child.list__item--selected.list__item:before {
	content: "";
    display: block;
    position: absolute;
    bottom: -13px;
    left: 50%;
	transform: translate(-50%,0);
    width: calc(100% - 15px);
    height: 1px;
    background-color: #929292;
	z-index: 1;
}

.list .list__item--brigade {
	color: #FF9500;
	animation: neon-command-brigade 1.5s linear infinite;
}
.list .list__item--brigade .list__item__label {
	color: #FF9500;
}
.list .list__item--selected.list__item--brigade {
	background-color: #FF9500;
	color: #FFF;
}
.list .list__item--selected.list__item--brigade .list__item__label {
	color: #FFF;
}

.list .list__item--abandon {
	color: #FF3B30;
	animation: neon-command-abandon 1.5s linear infinite;
}
.list .list__item--abandon .list__item__label {
	color: #FF3B30;
}
.list .list__item--selected.list__item--abandon {
	background-color: #FF3B30;
	color: #FFF;
}
.list .list__item--selected.list__item--abandon .list__item__label {
	color: #FFF;
}

.list .list__item--visual {
	color: #FFCC00;
	animation: neon-command-silence 1.5s linear infinite;
}
.list .list__item--visual .list__item__label {
	color: #FFCC00;
}
.list .list__item--selected.list__item--visual {
	background-color: #FFCC00;
	color: #FFF;
}
.list .list__item--selected.list__item--visual .list__item__label {
	color: #FFF;
}

.list .list__item--reset {
	color: #34C759;
	animation: neon-command-reset 3s linear infinite;
}
.list .list__item--reset .list__item__label {
	color: #34C759;
}
.list .list__item--selected.list__item--reset {
	background-color: #34C759;
	color: #FFF;
}
.list .list__item--selected.list__item--reset .list__item__label {
	color: #FFF;
}

@media(max-width: 1400px)
{
	.list {
		min-height: calc(100vh - 35vh);
		max-height: calc(100vh - 35vh);
	}
	
}


/* EVENTS KPI */
.events-kpi-trigger {
	display: none;
}
.events-kpi-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	transition: all .2s linear;
}

.events-kpi-container__item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.events-kpi-container__item button {
	width: 52px;
	height: 52px;
	overflow: visible;
}
.events-kpi-container__item button i {
	color: #FFF;
}

.events-kpi-trigger:not(:checked) + .events-kpi-container {
	max-height: 0;
	padding: 0px 0;
	border-top: 0px solid #FFF;
}
.events-kpi-trigger:checked + .events-kpi-container {
	max-height: 9999;
	padding: 10px 0;
	border-top: 1px solid #FFF;
}

/* COMANDOS */
.command {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: #000;
	margin: 25px 60px !important;
	/* transition: unset !important; */
	transition: all 1.5s linear;
}
.command--alarm {
	border: 2px solid #FF3B30 !important;
}
.command--alarm:hover {
	border: 2px solid #c90b00 !important;
}
.command--reset {
	border: 2px solid #34C759 !important;
}
.command--reset:hover {
	border: 2px solid #2E7D32 !important;
}
.command:disabled {
	background-color: #929292;
	border-color: #929292 !important;
	cursor: not-allowed;
}
.command__title {
	font-size: 14pt;
	font-weight: 400;
	color: inherit;
	text-transform: none;
	margin-top: 0;
    margin-bottom: 0;
}
.command__local {
	font-size: 12pt;
	font-weight: 400;
    color: inherit;
    text-transform: none;
    margin-top: 0;
    margin-bottom: 0;
}
.command__icon { 
	display: flex;
	align-items: center;
	justify-content: center;
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 35px auto;
}
.command__icon__image {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	filter: brightness(0%);
}

.command--triggered {
	pointer-events: none;
	animation: alarm-on 1.5s linear infinite;
}

.command--triggered .command__icon__image {
	animation: alarm-on-icon 1.5s linear infinite; 
}

.command .command__icon .command__icon__loading {
	display: none;
}

.command--loading {
	background-color: transparent !important;
    pointer-events: none !important;
}

.command--loading .command__icon .command__icon__image {
	display: none;
}
.command--loading .command__icon .command__icon__loading {
	display: block;
}

.command--alarm.command--loading {
	color: #FF3B30 !important;
}
.command--reset.command--loading {
	color: #34C759 !important;
}
.command--alarm.command--block {
	background-color: #c90b00 !important;
	border-color: #c90b00 !important;
	pointer-events: none;
}
.command--reset.command--block {
	background-color: #2E7D32 !important;
	border-color: #2E7D32 !important;
	pointer-events: none;
}

@keyframes reset-on {
	0% {
		color: #FFF;
		background-color: #34C759;
		border-color: 1px solid #34C759;
	}
	50% {
		color: #FFF;
		background-color: transparent;
		border-color: 1px solid #34C759;
	}
	100% {
		color: #FFF;
		background-color: #34C759;
		border-color: 1px solid #34C759;
	}
}
/* Defina a animação com @keyframes */
@keyframes alarm-on {
	0% {
		color: #000;
		background-color: #FF3B30;
		border-color: 2px solid #FF3B30;
	}
	50% {
		color: #FF3B30;
		background-color: transparent;
		border-color: 2px solid #FF3B30;
	}
	100% {
		color: #000;
		background-color: #FF3B30;
		border-color: 2px solid #FF3B30;
	}
}

@keyframes alarm-on-icon {
	0% {
		filter: brightness(0%);
	}
	50% {
		filter: invert(45%) sepia(92%) saturate(5087%) hue-rotate(344deg) brightness(110%) contrast(103%);
	}
	100% {
		filter: brightness(0%);
	}
}

/* Grid */

.grid {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
}
.grid--left{
	justify-content: flex-start;
}
.grid--right{
	justify-content: flex-end;
}
.grid--middle {
	align-items: center;
}
.grid--stretch {
	align-items: stretch;
}
.grid--nowrap {
	flex-wrap: nowrap;
}

.grid .grid__col {
	flex: 1;
	box-sizing: border-box;
}

.grid .grid__col.grid__col--column {
	display: flex;
	flex-direction: column;
}

.grid .grid__col[data-grid-col="12"] { flex: 1 1 100%; }
.grid .grid__col[data-grid-col="11"] { flex: 1 1 91.63%; }
.grid .grid__col[data-grid-col="10"] { flex: 1 1 83.3%; }
.grid .grid__col[data-grid-col="9"] { flex: 1 1 74.97%; }
.grid .grid__col[data-grid-col="8"] { flex: 1 1 66.64%; }
.grid .grid__col[data-grid-col="7"] { flex: 1 1 58.31%; }
.grid .grid__col[data-grid-col="6"] { flex: 1 1 50%; }
.grid .grid__col[data-grid-col="5"] { flex: 1 1 41.65%; }
.grid .grid__col[data-grid-col="4"] { flex: 1 1 33.32%; }
.grid .grid__col[data-grid-col="3"] { flex: 1 1 24.9%; }
.grid .grid__col[data-grid-col="2"] { flex: 1 1 16.6%; }
.grid .grid__col[data-grid-col="1"] { flex: 1 1 8.3%; }

/* Small (sm) */
@media (min-width: 576px) {
	.grid .grid__col { flex: 1 1 auto; }
}

/* Medium (md) */
@media (min-width: 768px) {
	.grid .grid__col[data-grid-col="12"] { flex: 1 1 100%; }
	.grid .grid__col[data-grid-col="6"] { flex: 1 1 50%; }
	.grid .grid__col[data-grid-col="4"] { flex: 1 1 33.33%; }
	.grid .grid__col[data-grid-col="3"] { flex: 1 1 25%; }
	.grid .grid__col[data-grid-col="2"] { flex: 1 1 16.66%; }
}

/* Large (lg) */
@media (min-width: 992px) {
	.grid .grid__col[data-grid-col="12"] { flex: 1 1 100%; }
	.grid .grid__col[data-grid-col="8"] { flex: 1 1 66.66%; }
	.grid .grid__col[data-grid-col="6"] { flex: 1 1 50%; }
	.grid .grid__col[data-grid-col="4"] { flex: 1 1 33.33%; }
	.grid .grid__col[data-grid-col="3"] { flex: 1 1 25%; }
}

/* Extra large (xl) */
@media (min-width: 1200px) {
	.grid .grid__col[data-grid-col="12"] { flex: 1 1 100%; }
	.grid .grid__col[data-grid-col="8"] { flex: 1 1 66.66%; }
	.grid .grid__col[data-grid-col="6"] { flex: 1 1 50%; }
	.grid .grid__col[data-grid-col="4"] { flex: 1 1 33.33%; }
	.grid .grid__col[data-grid-col="3"] { flex: 1 1 25%; }
}

.grid__col--middle {
	align-items: center;
}
.grid__col--center {
	justify-content: center;
}
.grid__col--right {
	justify-content: flex-end;
}

.grid .grid__item {
	flex: 1;
	box-sizing: border-box;
}
.grid .grid__item--full {
	flex: 1 1 100%;
	box-sizing: border-box;
}

.grid[data-grid-xs-columns="1"] .grid__item { flex: 1 1 100%; }
.grid[data-grid-xs-columns="2"] .grid__item { flex: 1 1 50%; }
.grid[data-grid-xs-columns="3"] .grid__item { flex: 1 1 33.33%; }
.grid[data-grid-xs-columns="4"] .grid__item { flex: 1 1 25%; }
.grid[data-grid-xs-columns="5"] .grid__item { flex: 1 1 20%; }
.grid[data-grid-xs-columns="6"] .grid__item { flex: 1 1 16.66%; }
.grid[data-grid-xs-columns="7"] .grid__item { flex: 1 1 14.28571%; }
.grid[data-grid-xs-columns="8"] .grid__item { flex: 1 1 12.5%; }
.grid[data-grid-xs-columns="9"] .grid__item { flex: 1 1 11.11%; }
.grid[data-grid-xs-columns="10"] .grid__item { flex: 1 1 10%; }
.grid[data-grid-xs-columns="11"] .grid__item { flex: 1 1 9.09%; }
.grid[data-grid-xs-columns="12"] .grid__item { flex: 1 1 8.3%; }

/* Small devices (sm) */
@media (min-width: 576px) {
	.grid[data-grid-sm-columns="1"] .grid__item { flex: 1 1 100%; }
	.grid[data-grid-sm-columns="2"] .grid__item { flex: 1 1 50%; }
	.grid[data-grid-sm-columns="3"] .grid__item { flex: 1 1 33.33%; }
	.grid[data-grid-sm-columns="4"] .grid__item { flex: 1 1 25%; }
	.grid[data-grid-sm-columns="5"] .grid__item { flex: 1 1 20%; }
	.grid[data-grid-sm-columns="6"] .grid__item { flex: 1 1 16.66%; }
	.grid[data-grid-sm-columns="7"] .grid__item { flex: 1 1 14.28571%; }
	.grid[data-grid-sm-columns="8"] .grid__item { flex: 1 1 12.5%; }
	.grid[data-grid-sm-columns="9"] .grid__item { flex: 1 1 11.11%; }
	.grid[data-grid-sm-columns="10"] .grid__item { flex: 1 1 10%; }
	.grid[data-grid-sm-columns="11"] .grid__item { flex: 1 1 9.09%; }
	.grid[data-grid-sm-columns="12"] .grid__item { flex: 1 1 8.3%; }
  }
  
  /* Medium devices (md) */
  @media (min-width: 768px) {
	.grid[data-grid-md-columns="1"] .grid__item { flex: 1 1 100%; }
	.grid[data-grid-md-columns="2"] .grid__item { flex: 1 1 50%; }
	.grid[data-grid-md-columns="3"] .grid__item { flex: 1 1 33.33%; }
	.grid[data-grid-md-columns="4"] .grid__item { flex: 1 1 25%; }
	.grid[data-grid-md-columns="5"] .grid__item { flex: 1 1 20%; }
	.grid[data-grid-md-columns="6"] .grid__item { flex: 1 1 16.66%; }
	.grid[data-grid-md-columns="7"] .grid__item { flex: 1 1 14.28571%; }
	.grid[data-grid-md-columns="8"] .grid__item { flex: 1 1 12.5%; }
	.grid[data-grid-md-columns="9"] .grid__item { flex: 1 1 11.11%; }
	.grid[data-grid-md-columns="10"] .grid__item { flex: 1 1 10%; }
	.grid[data-grid-md-columns="11"] .grid__item { flex: 1 1 9.09%; }
	.grid[data-grid-md-columns="12"] .grid__item { flex: 1 1 8.3%; }

	.grid .grid__item--no-grow { flex-grow: 0 !important; }
  }
  
  /* Large devices (lg) */
  @media (min-width: 992px) {
	.grid[data-grid-lg-columns="1"] .grid__item { flex: 1 1 100%; }
	.grid[data-grid-lg-columns="2"] .grid__item { flex: 1 1 50%; }
	.grid[data-grid-lg-columns="3"] .grid__item { flex: 1 1 33.33%; }
	.grid[data-grid-lg-columns="4"] .grid__item { flex: 1 1 25%; }
	.grid[data-grid-lg-columns="5"] .grid__item { flex: 1 1 20%; }
	.grid[data-grid-lg-columns="6"] .grid__item { flex: 1 1 16.66%; }
	.grid[data-grid-lg-columns="7"] .grid__item { flex: 1 1 14.28571%; }
	.grid[data-grid-lg-columns="8"] .grid__item { flex: 1 1 12.5%; }
	.grid[data-grid-lg-columns="9"] .grid__item { flex: 1 1 11.11%; }
	.grid[data-grid-lg-columns="10"] .grid__item { flex: 1 1 10%; }
	.grid[data-grid-lg-columns="11"] .grid__item { flex: 1 1 9.09%; }
	.grid[data-grid-lg-columns="12"] .grid__item { flex: 1 1 8.3%; }

	.grid .grid__item--no-grow { flex-grow: 0 !important; }
  }
  
  /* Extra large devices (xl) */
  @media (min-width: 1200px) {
	.grid[data-grid-xl-columns="1"] .grid__item { flex: 1 1 100%; }
	.grid[data-grid-xl-columns="2"] .grid__item { flex: 1 1 50%; }
	.grid[data-grid-xl-columns="3"] .grid__item { flex: 1 1 33.33%; }
	.grid[data-grid-xl-columns="4"] .grid__item { flex: 1 1 25%; }
	.grid[data-grid-xl-columns="5"] .grid__item { flex: 1 1 20%; }
	.grid[data-grid-xl-columns="6"] .grid__item { flex: 1 1 16.66%; }
	.grid[data-grid-xl-columns="7"] .grid__item { flex: 1 1 14.28571%; }
	.grid[data-grid-xl-columns="8"] .grid__item { flex: 1 1 12.5%; }
	.grid[data-grid-xl-columns="9"] .grid__item { flex: 1 1 11.11%; }
	.grid[data-grid-xl-columns="10"] .grid__item { flex: 1 1 10%; }
	.grid[data-grid-xl-columns="11"] .grid__item { flex: 1 1 9.09%; }
	.grid[data-grid-xl-columns="12"] .grid__item { flex: 1 1 8.3%; }

	.grid .grid__item--no-grow { flex-grow: 0 !important; }
  }

  /* Extra large devices (xl) */
  @media (min-width: 1400px) {
	.grid[data-grid-2x-columns="1"] .grid__item { flex: 1 1 100%; }
	.grid[data-grid-2x-columns="2"] .grid__item { flex: 1 1 50%; }
	.grid[data-grid-2x-columns="3"] .grid__item { flex: 1 1 33.33%; }
	.grid[data-grid-2x-columns="4"] .grid__item { flex: 1 1 25%; }
	.grid[data-grid-2x-columns="5"] .grid__item { flex: 1 1 20%; }
	.grid[data-grid-2x-columns="6"] .grid__item { flex: 1 1 16.66%; }
	.grid[data-grid-2x-columns="7"] .grid__item { flex: 1 1 14.28571%; }
	.grid[data-grid-2x-columns="8"] .grid__item { flex: 1 1 12.5%; }
	.grid[data-grid-2x-columns="9"] .grid__item { flex: 1 1 11.11%; }
	.grid[data-grid-2x-columns="10"] .grid__item { flex: 1 1 10%; }
	.grid[data-grid-2x-columns="11"] .grid__item { flex: 1 1 9.09%; }
	.grid[data-grid-2x-columns="12"] .grid__item { flex: 1 1 8.3%; }

	.grid .grid__item--no-grow { flex-grow: 0 !important; }
  }
  /* Extra large devices (xl) */
  @media (min-width: 1600px) {
	.grid[data-grid-3x-columns="1"] .grid__item { flex: 1 1 100%; }
	.grid[data-grid-3x-columns="2"] .grid__item { flex: 1 1 50%; }
	.grid[data-grid-3x-columns="3"] .grid__item { flex: 1 1 33.33%; }
	.grid[data-grid-3x-columns="4"] .grid__item { flex: 1 1 25%; }
	.grid[data-grid-3x-columns="5"] .grid__item { flex: 1 1 20%; }
	.grid[data-grid-3x-columns="6"] .grid__item { flex: 1 1 16.66%; }
	.grid[data-grid-3x-columns="7"] .grid__item { flex: 1 1 14.28571%; }
	.grid[data-grid-3x-columns="8"] .grid__item { flex: 1 1 12.5%; }
	.grid[data-grid-3x-columns="9"] .grid__item { flex: 1 1 11.11%; }
	.grid[data-grid-3x-columns="10"] .grid__item { flex: 1 1 10%; }
	.grid[data-grid-3x-columns="11"] .grid__item { flex: 1 1 9.09%; }
	.grid[data-grid-3x-columns="12"] .grid__item { flex: 1 1 8.3%; }

	.grid .grid__item--no-grow { flex-grow: 0 !important; }
  }
  @media (min-width: 1800px) {
	.grid[data-grid-4x-columns="1"] .grid__item { flex: 1 1 100%; }
	.grid[data-grid-4x-columns="2"] .grid__item { flex: 1 1 50%; }
	.grid[data-grid-4x-columns="3"] .grid__item { flex: 1 1 33.33%; }
	.grid[data-grid-4x-columns="4"] .grid__item { flex: 1 1 25%; }
	.grid[data-grid-4x-columns="5"] .grid__item { flex: 1 1 20%; }
	.grid[data-grid-4x-columns="6"] .grid__item { flex: 1 1 16.66%; }
	.grid[data-grid-4x-columns="7"] .grid__item { flex: 1 1 14.28571%; }
	.grid[data-grid-4x-columns="8"] .grid__item { flex: 1 1 12.5%; }
	.grid[data-grid-4x-columns="9"] .grid__item { flex: 1 1 11.11%; }
	.grid[data-grid-4x-columns="10"] .grid__item { flex: 1 1 10%; }
	.grid[data-grid-4x-columns="11"] .grid__item { flex: 1 1 9.09%; }
	.grid[data-grid-4x-columns="12"] .grid__item { flex: 1 1 8.3%; }

	.grid .grid__item--no-grow { flex-grow: 0 !important; }
  }


/* InfoBox */

.info-box-container {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

.info-box {
	max-width: 250px;
	min-height: 250px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	border: 3px solid #FFF;
	padding: 15px;
	border-radius: 40px;
	margin: 0 10px 35px;
	transition: all .2s linear;
}

.info-box:hover {
	box-shadow: 0 0 7px 0;
}

.info-box--full {
	max-width: 100%;
	min-height: auto;
}

.info-box--curved {
	border-radius: 20px;
}

.info-box--rounded {
	border-radius: 40px;
}

.info-box__header {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* margin-bottom: 15px; */
}
.info-box__title {
	font-size: 18px;
	margin: 0;
}
.info-box__body {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: all .2s linear;
}
.info-box__footer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	text-align: center;
	margin-top: 15px;
	transition: all .2s linear;
}

/* CARDS */
.info-card{
	/* height: 55vh !important; */
	min-height: 480px;
	overflow: hidden;
	padding: 0px;
	/* min-width: 23.7vw; */
}

@media (max-width: 576px) {
	.info-card{
		/* height: 48vh !important; */
		overflow: hidden;
		padding: 0px;
		/* min-width: 85vw;
		max-width: 85vw !important; */
		/* margin-inline: 30px !important; */
	}
}

.info-card__header{
	height: 4.5rem;
	color: #383838;
	text-transform: uppercase;
	text-shadow: 0px -1px 0.7px rgba(56,56,56,1);
}
.info-card__title {
	font-size: 1.5em;
}

.info-card__footer{
	margin-top: 5px !important;	
}
.df-xxl-big {
    font-size: 8rem !important;
}

.df-xxl {
    font-size: 6rem !important;
}

.df-xl {
    font-size: 5rem !important;
}
.df-lg {
    font-size: 4rem !important;
}
.df-md {
    font-size: 3rem !important;
}
.df-sm {
    font-size: 2rem !important;
}
.df-xs-x {
	font-size: 1.3rem !important;
}
.df {
    display: flex;
    align-items: center;
    justify-content: center;
}
.button-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}
.loadbtn-me{
	margin-right: 10px;
}

.margin-top-drawer{
	position: relative;
	margin-top: 75px;
}

.grid-cards{
	/*display: ruby;	*/
	flex-direction: row;
	grid-gap:1rem;
	gap:0px;
	display: flex;
	flex-wrap: wrap;
	
}

.bg-color-card-green{
background-color: #34C759;
}
.bg-color-card-orange{
background-color: #FF9500;
}
.bg-color-card-yellow{
background-color: #FFCC00;
}
.bg-color-card-red{
background-color: #FF3B30;
}

.color-card-green{
color: #34C759;
}
.color-card-orange{
color: #FF9500;
}
.color-card-yellow{
color: #FFCC00;
}
.color-card-red{
color: #FF3B30;
}

@media(max-width: 1680px)
{
	
}
@media(max-width: 1400px)
{

}
@media(max-width: 1200px)
{

}
@media(max-width: 992px)
{

}
@media(max-width: 768px)
{

}
/* END CARDS */

.border-title {
	position: absolute;
	top: -10px;
	background: transparent;
	font-weight: bold;
	font-size: 0.8rem;
	color: #A1A1A1;
}

.invisible-button {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	display: inline-flex; /* Ensures the button adopts the size of the child */
	align-items: center;
	justify-content: center;
	min-height: 44px !important;
	height: 44px !important;
	cursor: pointer; /* Show pointer on hover */
}
.invisible-btn-figure {
	min-height: 44px !important;
}


.grid__item .info-box {
	max-width: calc(100% - 20px);
}

.grid[data-grid-xl-columns="1"] .grid__item .info-box { max-width: 100%; margin: 0 0 20px; }

@media (max-width: 576px) {
	.grid[data-grid-xs-columns="1"] .grid__item .info-box { max-width: 100%; margin: 0 0 20px; }
}
@media (max-width: 768px) {
	.grid[data-grid-sm-columns="1"] .grid__item .info-box { max-width: 100%; margin: 0 0 20px; }
}
@media (max-width: 992px) {
	.grid[data-grid-md-columns="1"] .grid__item .info-box { max-width: 100%; margin: 0 0 20px; }
}
@media (max-width: 1200px) {
	.grid[data-grid-lg-columns="1"] .grid__item .info-box { max-width: 100%; margin: 0 0 20px; }
}

/* REDE */
.amplifier-top { display: flex; flex-direction: column; }
.amplifier-top__header { display: flex; align-items: center; justify-content: center; }
.amplifier-top__body { display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; }

.amplifier-card { width: 100%; display: flex; flex-direction: column; }
.amplifier-card--row { width: 100%; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-between; }

.amplifier-card__icon {
	display: flex;
    align-items: center;
    justify-content: center;
	margin: 0;
}

.amplifier-card--footer {
	height: 0;
	justify-content: center;
	overflow-y: hidden;
	transition: all .2s linear;
}
.amplifier-card--actions {
	height: 48px;
	transition: all .2s linear;
}

.info-box .amplifier-card__icon svg {
	width: 1.2em;
	height: 1.2em;
}
.info-box--full .amplifier-card__icon svg {
	width: 1.5em;
	height: 1.5em;
}

.info-box:hover[data-status="online"] {
	border-color: #34C759;
	box-shadow: 0 0 7px 0 #34C759;
}
.info-box[data-status="offline"] {
	border-color: #FF3B30;
	box-shadow: 0 0 7px 0 #FF3B30;
}
.info-box[data-status="buscando"] {
	animation: neon-searching 1.5s linear infinite;
}
.info-box[data-status="LIGA"] {
	animation: neon-abandon 1.5s linear infinite;
}
.info-box[data-status="PANI"] {
	animation: neon-brigade 1.5s linear infinite;
}
.info-box[data-status="SILE"] {
	animation: neon-silence 1.5s linear infinite;
}

.info-box.info-box--primary {
	border-color: #5ACBFA;
}

@keyframes neon-searching {
	0% {
		border-color: #079EDF;
		box-shadow: 0 0 7px 0 #079EDF;
	}
	50% {
		border-color: #FFF;
		box-shadow: 0 0 0px 0 #FFF;
	}
	100% {
		border-color: #079EDF;
		box-shadow: 0 0 7px 0 #079EDF;
	}
}
@keyframes neon-abandon {
	0% {
		border-color: #FF3B30;
		box-shadow: 0 0 7px 0 #FF3B30;
	}
	50% {
		border-color: #FFF;
		box-shadow: 0 0 0px 0 #FFF;
	}
	100% {
		border-color: #FF3B30;
		box-shadow: 0 0 7px 0 #FF3B30;
	}
}
@keyframes neon-brigade {
	0% {
		border-color: #FF9500;
		box-shadow: 0 0 7px 0 #FF9500;
	}
	50% {
		border-color: #FFF;
		box-shadow: 0 0 0px 0 #FFF;
	}
	100% {
		border-color: #FF9500;
		box-shadow: 0 0 7px 0 #FF9500;
	}
}
@keyframes neon-silence {
	0% {
		border-color: #FFCC00;
		box-shadow: 0 0 7px 0 #FFCC00;
	}
	50% {
		border-color: #FFF;
		box-shadow: 0 0 0px 0 #FFF;
	}
	100% {
		border-color: #FFCC00;
		box-shadow: 0 0 7px 0 #FFCC00;
	}
}
@keyframes neon-reset {
	0% {
		border-color: #FFF;
		box-shadow: 0 0 0px 0 #FFF;
	}
	50% {
		border-color: #34C759;
		box-shadow: 0 0 7px 0 #34C759;
	}
	100% {
		border-color: #FFF;
		box-shadow: 0 0 0px 0 #FFF;
	}
}

@keyframes neon-command-searching {
	0% {
		border-color: #079EDF;
		box-shadow: 0 0 7px 0 #079EDF;
	}
	50% {
		border-color: #FFF;
		background-color: #282828;
		box-shadow: 0 0 0px 0 #FFF;
	}
	100% {
		border-color: #079EDF;
		box-shadow: 0 0 7px 0 #079EDF;
	}
}
@keyframes neon-command-abandon {
	0% {
		border-color: #FF3B30;
		box-shadow: 0 0 7px 0 #FF3B30;
	}
	50% {
		border-color: #FFF;
		background-color: #282828;
		box-shadow: 0 0 0px 0 #FFF;
	}
	100% {
		border-color: #FF3B30;
		box-shadow: 0 0 7px 0 #FF3B30;
	}
}
@keyframes neon-command-brigade {
	0% {
		border-color: #FF9500;
		box-shadow: 0 0 7px 0 #FF9500;
	}
	50% {
		border-color: #FFF;
		background-color: #282828;
		box-shadow: 0 0 0px 0 #FFF;
	}
	100% {
		border-color: #FF9500;
		background-color: #282828;
		box-shadow: 0 0 7px 0 #FF9500;
	}
}
@keyframes neon-command-silence {
	0% {
		border-color: #FFCC00;
		box-shadow: 0 0 7px 0 #FFCC00;
	}
	50% {
		border-color: #FFF;
		background-color: #282828;
		box-shadow: 0 0 0px 0 #FFF;
	}
	100% {
		border-color: #FFCC00;
		box-shadow: 0 0 7px 0 #FFCC00;
	}
}
@keyframes neon-command-reset {
	0% {
		border-color: #FFF;
		box-shadow: 0 0 0px 0 #FFF;
	}
	50% {
		border-color: #34C759;
		background-color: #282828;
		box-shadow: 0 0 7px 0 #34C759;
	}
	100% {
		border-color: #FFF;
		box-shadow: 0 0 0px 0 #FFF;
	}
}

.amplifier-card__content {
	width: 100%;
	margin: 0 10px;
}

.info-box .amplifier-card__title {
	font-size: 14px;
    margin: 0px;
}
.info-box .amplifier-card__status {
	font-size: 13px;
	margin: 0px;
}

.info-box--full .amplifier-card__title {
	font-size: 16px;
    margin: 0px;
}
.info-box--full .amplifier-card__status {
	font-size: 14px;
	margin: 0px;
}

/* FILTER CONTROL */
.filter-control {

}

.filter-control__trigger {
	display: none;
}

/* KPIs */

.kpi {
	display: flex;
	flex: 1;
	flex-direction: column;
	border-width: 3px;
	border-color: #FFF;
	border-style: solid;
	border-radius: 20px;
	padding: 10px;
	margin: 5px 10px;
	transition: all .2s linear;
}
.kpi:hover {
	box-shadow: 0 0 7px 0;

}
.kpi:first-child {
	margin-left: 0;
}
.kpi:last-child {
	margin-right: 0;
}
.kpi--filter {
	cursor: pointer;
}

.filter-control__trigger:checked + .kpi--filter[for="filter-all-amplifiers"] {
	box-shadow: 0 0 7px 0;
	transition: all .2s linear;
}
.filter-control__trigger:checked + .kpi--filter[for="filter-online-amplifiers"] {
	border-color: #34C759;
	box-shadow: 0 0 7px 0 #34C759;
	transition: all .2s linear;
}
.filter-control__trigger:checked + .kpi--filter[for="filter-searching-amplifiers"] {
	border-color: #079EDF;
	box-shadow: 0 0 7px 0 #079EDF;
	transition: all .2s linear;
}
.filter-control__trigger:checked + .kpi--filter[for="filter-offline-amplifiers"] {
	border-color: #FF3B30;
	box-shadow: 0 0 7px 0 #FF3B30;
	transition: all .2s linear;
}

.kpi__header {
	margin-bottom: 10px;
}
.kpi__header__title {
	display: block;
	font-weight: 400;
}
.kpi__body {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.kpi__body__icon {
	width: 42px !important;
	height: 42px !important;
	fill: #FFF;
	color: #FFF;
}
.kpi__body__value {
	font-size: 32px;
	font-weight: bold;
}

@media(max-width: 1024px) {
	.kpi--filter {
		justify-content: space-between;
	}
	.kpi__header__title {
		display: block;
		font-size: 13px;
		font-weight: 400;
		line-height: 16px;
	}
}

@media(max-width: 767px) {	
	.filter-control {
		align-items: unset;
    	justify-content: flex-start;
	}
	.kpi--filter {
		max-width: 150px;
		justify-content: space-between;
	}
}

/* RELATÓRIOS */
.MuiTabs-fixed .MuiTabs-flexContainer button[aria-selected="true"] { border-bottom: 2px solid #079EDF; transition: all .2s lienar; }
.MuiTabs-fixed .MuiTabs-flexContainer button[aria-selected="false"] { border-bottom: 2px solid transparent; transition: all .2s lienar; }

.tab__item {
	width: 100%;
}

.filter {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.filter__column {
	width: calc(100% / 3 - 30px);
	margin-bottom: 25px;
}
.filter__column--fw {
	width: 100%;
}

.filter__item {
	padding: 0;
}

@media(max-width: 600px){
	.filter__column {
		width: 100%;
		margin-bottom: 25px;
	}
}

@media(max-width: 650px){
	header .logo {
		width: 42px;
	}
	
	header .logo img {
		max-width: unset;
		position: absolute;
		top: 50%;
		left: 0%;
		transform: translate(0%,-50%);
		object-fit: cover;
		object-position: left;
	}

	.MuiIconButton-edgeStart {
		margin-right: 5px !important;
	}

	.item1.MuiBox-root {
		width: auto;
		overflow: visible;
	}
	.menu-lateral[data-view-touch="false"],
	.menu-lateral[data-view-touch="true"] {
		display: none;
	}
	.menu-mobile .MuiMenu-paper
	{
		top: 58px !important;
		left: 15px !important;
		overflow-y: scroll;
		max-height: calc(100% - 100px);
	}

	/* DARK MODE */
	.menu-mobile[data-dark-mode="true"] ul {
		background-color: #5E5E5E !important;
	}
	.menu-mobile[data-dark-mode="true"] .menu-mobile__item {
		color: #FFF;
	}
	.menu-mobile[data-dark-mode="true"] .menu-mobile__item i.df-i {
		filter: invert(1);
	}

	/* LIGHT MODE */
	.menu-mobile[data-dark-mode="false"] ul {
		background-color: #D6D6D6 !important;
	}
	.menu-mobile[data-dark-mode="false"] .menu-mobile__item {
		color: #5E5E5E;
	}
	.menu-mobile[data-dark-mode="false"] .menu-mobile__item i.df-i {
		filter: invert(34%) sepia(0%) saturate(1206%) hue-rotate(245deg) brightness(102%) contrast(89%);
	}

	/* ESPECIAL */
	.menu-mobile .menu-mobile__item.menu-mobile__item--comandos {
		color: #FF3B30;
	}
	.menu-mobile .menu-mobile__item.menu-mobile__item--comandos i.df-i {
		filter: invert(29%) sepia(42%) saturate(3683%) hue-rotate(343deg) brightness(105%) contrast(100%) !important;
	}

	.menu-mobile .menu-mobile__item:hover {
		color: #5ACBFA;
	}
	.menu-mobile .menu-mobile__item:hover i.df-i {
		filter: invert(70%) sepia(23%) saturate(950%) hue-rotate(155deg) brightness(98%) contrast(101%);
	}

	.menu-mobile .menu-mobile__item svg,
	.menu-mobile .menu-mobile__item i.df-i {
		margin-right: 10px;
		color: inherit;
	}

	.menu-mobile .menu-mobile__item svg.MuiSvgIcon-colorError {
		color: #FF3B30;
	}

	.info-box-container {
		margin: 0;
	}
	
	

	/* .header-action[data-action="health-system"],
	.header-action[data-action="port-com"],
	.header-action[data-action="commands"]{ 
		display: none;
	} */
}

/* .relatory-print * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

/*.relatory-print {
	min-width: 80vw;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	color: #000;
}

.relatory-print h2 {
	font-size: 24px;
}
.relatory-print h3 {
	font-size: 16px;
}

.relatory-print table {
	width: 100%;
	page-break-inside: auto;
	border-collapse: collapse;
	border-spacing: 0;
}

.relatory-print td, th {
	font-size: 14px;
	text-align: center;
}

.relatory-print .docHeader {
	font-size: 16px;
	color: grey;
}

.relatory-print .table-header {
	line-height: 10px;
}

.relatory-print .table-position {
	margin-bottom: -8px;*/
	/* margin-top: -30px; */
/*}

.relatory-print .table-data {
	page-break-inside: avoid;
	page-break-after: auto;
}

.relatory-print .image {
	width: auto;
	height: 100%;
}

.relatory-print .image-delta--status-amp {
	width: auto;
	height: 55%;
	margin-bottom: 0px;
	position: center;
}

.relatory-print .image-logo {
	width: auto;
	height: 100px;
	margin-right: 50px;
	position: center;
	margin-bottom: 15px;
}

.relatory-print .image-delta {
	width: auto;
	height: 70%;
	margin-bottom: 8px;
	position: center;
}

.relatory-print .item {
	margin-top: 10px;
	margin-bottom: 20px;
	page-break-inside: avoid;
}

.relatory-print .item-header-cell {
	height: 10px;
	page-break-inside: avoid;
	font-weight:bold;
}

.relatory-print .item-cell {
	height: 10px;
	page-break-inside: avoid;
}

.relatory-print .filters {
	margin-top: 25px;
	margin-bottom: 20px;
	padding: 5px;
	text-align: center;
	border: 1px solid #000;
}*/